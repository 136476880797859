import api from '@apiInstance';

export default {
  register(user) {
    return api.post('user', user);
  },
  forgotPassword(body) {
    return api.request({
      url: '/users/forgot-password',
      method: 'POST',
      body,
      type: 'reset-password-request',
    });
  },
  resetPassword(body) {
    return api.request({
      url: '/users/reset-password',
      method: 'POST',
      body,
      type: 'reset-password-request',
    });
  },
  verifyEmail(body) {
    return api.request({
      url: '/users/verify-email',
      method: 'POST',
      body,
      type: 'email-verify-request',
    });
  },
  setPassword(body) {
    return api.request({
      url: '/users/set-password',
      method: 'POST',
      body,
      type: 'set-password',
    });
  },
  resendVerification(body) {
    return api.request({
      url: '/users/resend-verification',
      method: 'POST',
      body,
      type: 'resend-verification',
    });
  },
  add(user) {
    return api.post('users', user);
  },
  get(id) {
    return api.fetch(`users/${id}/`);
  },
  companyusers(params) {
    return api.fetch('users/companyusers', { params });
  },
  async list(params) {
    const data = await api.fetch('user', { params });
    return data;
  },
  update(user) {
    return api.patch('user', user);
  },
  delete(id) {
    return api.remove('user', id);
  },
  changePassword(body) {
    return api.request({
      url: `/users/${body.id}/password`,
      method: 'PATCH',
      body,
      type: 'update-password-request',
    });
  },
  generateAuthenticator(id, body) {
    body.id = id;
    return api.request({
      url: '/users/' + id + '/generate-auth-code',
      method: 'PATCH',
      body,
      type: 'authenticator-request',
    });
  },
  authenticator(id, body) {
    body.id = id;
    return api.request({
      url: '/users/' + id + '/authenticator',
      method: 'PATCH',
      body,
      type: 'authenticator-request',
    });
  },
  sendSMS(id) {
    return api.request({
      url: '/users/' + id + '/send-verify-sms',
      method: 'PATCH',
      body: { id },
      type: 'mobile-verify-request',
    });
  },
  verifyMobile(id, body) {
    body.id = id;
    return api.request({
      url: '/users/' + id + '/verify-mobile',
      method: 'PATCH',
      body,
      type: 'mobile-verify-request',
    });
  },
  recoveryQuestions(id, questions) {
    return api.request({
      url: '/users/' + id + '/recovery-questions',
      method: 'PATCH',
      body: { id, questions },
      type: 'recovery-questions-request',
    });
  },
  getRecoveryQuestions(body) {
    return api.request({
      url: '/users/recovery-questions',
      method: 'POST',
      body,
      type: 'login-requests',
    });
  },
  disableMFA(body) {
    return api.request({
      url: '/users/disable-mfa',
      method: 'POST',
      body,
      type: 'mfa-disable-request',
    });
  },
  getAvailableTechnicians(params) {
    return api.fetch(`users/getavailabletechnicians/`, { params });
  },
};
